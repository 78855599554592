//EMPLOYEE SITE STYLES
@import "~react-bootstrap-typeahead/css/Typeahead.css";
@import "./styles/customer.scss";
@import "remirror/styles/all.css";

.side-nav-spinner-wrapper {
  > div {
    padding: 20px 0 !important;
  }
}

.rbt-menu > .dropdown-item {
  white-space: normal;
  padding-top: 5px;
  padding-bottom: 5px;
}

.search-box {
  width: 350px;
  max-width: 100%;
}

.document-body {
  flex: 0 1 auto;
  display: flex;
  flex-flow: row;
  padding: 0;
  align-items: flex-start;
  position: relative;
  background: darken($color: #f4f5f9, $amount: 3%);
  z-index: 8;
  height: calc(100% - 20px);

  > div:first-child {
    flex: 1;
    min-height: 100%;
    //padding-left: 20px;
    overflow: auto;
    background-color: #fff;
    //border: solid 1px darken($color: #f4f5f9, $amount: 7%);

    [data-empty-text] {
      background: none !important;
    }

    // &::before {
    //   content: " ";
    //   width: 30px;
    //   position: absolute;
    //   top: 0;
    //   bottom: 0;
    //   left: 0;
    //   border-top-left-radius: 3px;
    //   border-bottom-left-radius: 3px;
    // }

    display: flex;

    > div {
      padding: 2px;

      &.remirror-theme {
        flex: 1;

        > div.remirror-editor-wrapper {
          flex: 1;
          padding: 0;

          > div {
            height: 100%;
            > div {
              height: 100%;
              max-height: calc(100vh - 150px);
            }

            // [contenteditable="true"] {
            //   a {
            //     &:hover {
            //       position: relative;

            //       &::after {
            //         content: "Double Click to Edit";
            //         position: absolute;
            //         top: 100%;
            //         left: 0;
            //         z-index: 1;
            //         background-color: #eee;
            //         cursor: pointer;
            //         padding: 1px 4px;
            //         border-radius: 3px;
            //         white-space: nowrap;
            //       }
            //     }
            //   }
            // }
          }
        }
      }

      // .block-menu-trigger {
      //   margin-left: -35px;
      // }
    }

    textarea {
      border: none;
      border-bottom: solid 1px #ddd;
      &:focus,
      &:active {
        outline: none;
      }
    }
  }
}

.row-red {
  background-color: lighten($red, 30%);
}

.ftp-manager {
  > div:first-child {
    flex: 1 0 auto;
    padding: 16px;
    padding-right: 0;
    max-width: 100%;
    //min-width: 350px;
  }
  > div:last-child {
    flex: 0 1 300px;
    // border-right: solid 1px #eee;
    background-color: #efefef;
    max-width: 100%;
  }
}

.ftp-objects {
  > div {
    padding-bottom: 4px;
  }
}

.ftp-folder,
.ftp-file {
  cursor: pointer;
  display: flex;
  flex-flow: row;
  align-items: center;
  position: relative;
  user-select: none;
  gap: 5px;
  //padding-right: 5px;
  margin-right: 16px;

  > i {
    flex: 0 0 20px;
    text-align: center;
    z-index: 10;
    width: 20px;
    padding-left: 5px;
  }
  > div.ftp-file-actions {
    display: none;
    white-space: nowrap;
    > i {
      margin-left: 12px;
    }
  }
  > span {
    flex: 1 1 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 4px 0;
    //padding-right: 10px;
  }
  > div.ftp-action {
    white-space: nowrap;
    font-weight: bold;
  }
  &:hover:not(.other-file) {
    background-color: #eee;
    // > div.ftp-file-actions {
    //   display: block;
    // }
  }

  &.other-file {
    opacity: 0.5;
    cursor: default;
  }
  &.current-file {
    font-weight: bold;
    background-color: #efefef;
    margin-right: 0;
    padding-right: 16px;
  }
  &.current-folder {
    opacity: 0.5;
    > div.ftp-action {
      display: none;
    }
  }
  &.other-folder {
    //border: dashed 1px #bbb;
    background-color: lighten($color: $yellow, $amount: 30%);
  }
}
.ftp-sub-folder {
  padding-top: 4px;
  padding-left: 16px;
  position: relative;
  &::before {
    content: " ";
    border-left: solid 1px #ccc;
    position: absolute;
    left: 10px;
    top: -10px;
    height: calc(100% - 10px);
    z-index: 1;
  }

  div.ftp-file,
  div.ftp-folder {
    position: relative;
    &::before {
      content: " ";
      border-bottom: solid 1px #ccc;
      width: 10px;
      position: absolute;
      left: -5px;
      top: -15px;
      height: 100%;
      z-index: 1;
    }
  }
}

.report-card {
  cursor: pointer;
  &:hover {
    background-color: #efefef;
  }
}

@media (max-width: 770px) {
  .spendbysource-report {
    > div:first-child {
      order: 2;
    }
    > div:last-child {
      order: 1;
      width: 100% !important;
    }
  }
}

#dashboard-template-body {
  .dashboard-template {
    [data-text] {
      //background-color: #efefef;
      border-bottom: dashed 2px lightblue;
      min-width: 100px;

      &:hover,
      &:focus,
      &:active {
        background-color: #efefef;
      }
    }

    i[data-text] {
      display: inline-block;
    }

    [data-image=""] {
      position: relative;
      text-align: center;
      cursor: pointer;
      &::before {
        content: "Change Banner Image";
        position: absolute;
        top: 40%;
        left: 0;
        right: 0;
        width: 200px;
        text-align: center;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.3);
        padding: 5px 10px;
        margin: 0 auto;
      }
    }
  }
}

// .table-container {
//   display: block;
//   > table {
//     display: block;
//     width: 100%;
//     > tbody {
//       display: block;
//       width: 100%;
//       > tr {
//         display: block;
//         width: 100%;
//       }
//     }
//   }
// }
