@import "../components/Layout.scss";

.dashboard {
  display: flex;
  flex-flow: row;
  gap: 20px;

  @media (max-width: 1150px) {
    flex-flow: column;
  }

  .dashboard-widgets {
    flex: 0 1 auto;
    min-width: 300px;
    display: flex;
    gap: 20px;
    flex-flow: column;
    > div {
      background: #fff;
      display: flex;
      padding: 20px;
      align-items: center;
      cursor: pointer;
      transition: $default_transtion;
      overflow: hidden;

      &:hover {
        box-shadow: 0 2px 5px 0 #bbb;

        > i {
          transform: scale(1) rotateZ(10deg);
          opacity: 1;
        }
      }

      > div {
        flex: 1 1 auto;
        > span {
          text-transform: uppercase;
          color: $grey;
        }
      }
      > i {
        font-size: 50px;
        color: $grey;
        transform: scale(0.8);
        transition: all ease-in-out 0.2s;
        opacity: 0.8;
      }
    }
  }
  .dashboard-feature {
    flex: 1 1 auto;
    background: #fff;
    padding: 20px;
    min-height: 200px;
  }
}

.order-guide {
  > div {
    font-size: 16px;
    margin-bottom: 20px;

    label {
      margin-bottom: 10px;
    }

    > div {
      > section {
        background: #fff;
        padding: 15px;
      }
    }
  }

  .order-guide-search {
    button,
    textarea,
    input {
      border-radius: 0;
    }
  }

  @media (max-width: 992px) {
    padding-top: 90px;
  }
}

.order-guide-widget {
  position: absolute;
  padding: 10px 15px;
  background-color: #fff;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  box-shadow: 0 3px 3px 0 #ddd;
  z-index: 11;
  gap: 10px;
  top: 64px;
  right: 40px;

  @media (max-width: 992px) {
    background: #eee;
    max-width: 100% !important;
    right: 0;
    left: 0;
  }

  @media (max-width: 600px) {
    top: 119px;
  }
}

.qty-gt-10-label {
  color: $orange;
}
.qty-gt-10 {
  border: dashed 1px $orange;
  border-bottom-width: 2px;
  border-bottom-style: solid;

  > td:nth-child(3),
  > td:nth-child(6) {
    font-weight: bold;
    color: $orange;
  }
}

.print-guide {
  table {
    background-color: #fff;
  }
}

.billing-center {
  .tab-content {
    background-color: #fff;
    padding: 20px;
    border: solid 1px #ddd;
    border-top: none;
  }
}

.print-wrapper {
  background-color: #efefef;
  padding: 10px 15px;
  margin-bottom: 15px;
}

@media print {
  .billing-table,
  #print-guide-table {
    thead {
      th {
        font-weight: bold;
        font-size: 15px !important;
      }
      border-bottom: solid 2px #ddd;
    }
    td,
    th {
      padding: 5px 5px !important;
      &:last-child {
        padding-right: 0 !important;
      }
      &:first-child {
        padding-left: 0 !important;
      }
    }
    td {
      font-size: 13px !important;
    }
  }

  .billing-center {
    .nav-tabs {
      display: none;
    }

    .tab-content {
      border: none;
      padding: 0;
    }
  }
}

.customer-documentation {
  display: flex;
  flex-flow: row;

  > div:first-child {
    border-right: solid 1px #eee;
    max-width: 300px;
    min-width: 200px;
  }
  > div:last-child {
    flex: 1;
    overflow: auto;
    img {
      max-width: 100%;
    }
  }

  @media (max-width: 700px) {
    > div:first-child {
      max-width: unset;
    }
    flex-flow: column;
  }
}

.selected-document {
  background-color: lighten($system_green, 40%);
  td {
    color: $system_green !important;
    font-weight: bold;
    small {
      font-weight: normal;
      color: $system_green !important;
    }
  }
}

.survey-page,
.unsubscribe-survey-page {
  text-align: center;
  padding: 20px;
  height: 100vh;
  background-size: cover;
  background-position: center;

  > div {
    max-width: 800px;
    margin: auto;
    padding-bottom: 30px;

    &.survey-form {
      background-color: #fff;
      padding: 20px 30px;
      border-top: solid 1px $olive;
    }
  }

  &.unsubscribe-survey-page {
    > div.unsubscribe-form {
      max-width: 500px;
      > div {
        background-color: #fff;
        padding: 20px 30px;
        border-top: solid 1px $olive;
      }
    }
  }

  .star-rating {
    display: flex;
    gap: 20px;
    margin-top: 10px;
    margin-bottom: 15px;

    > div:first-child {
      flex: 0 1 auto;
      width: 80px;
      text-align: center;
      padding: 10px 0;
      > i.fa {
        font-size: 50px;
        color: $olive;
      }
    }

    > div:last-child {
      flex: 1 1 auto;
      text-align: left;

      strong {
        font-size: 15px;
        color: $blue;
      }

      > i.fa {
        padding-right: 8px;
        font-size: 30px;
        cursor: pointer;

        &.fa-star {
          color: $orange;
        }
        &.fa-star-o {
          color: $grey;
          &:hover {
            color: $orange;
          }
        }
      }
      > div {
        min-height: 20px;
      }
    }
  }
}

.no-image {
  opacity: 0.15;
  text-align: center;
  padding: 30px 0;
  > i {
    font-size: 75px;
  }
}

.product-catalog,
.product-detail > div:last-child,
.dashboard-featured-items {
  .catalog-banner {
    display: flex;
    flex-flow: row;
    gap: 20px;

    > div {
      display: flex;
      flex-flow: column;
      gap: 20px;

      &:first-child {
        flex: 1 1 calc(66.7% + 10px);
      }
      &:last-child {
        flex: 1 1 calc(33.3% - 10px);
      }

      &:first-child:last-child {
        flex-flow: row;
        flex: 1 1 auto;
        gap: 20px;

        > div {
          flex: 1 1 33.3%;
        }
      }

      @media (max-width: 1000px) {
        &:first-child {
          flex: 1 1 50%;
        }
        &:last-child {
          flex: 1 1 50%;
        }
      }
    }

    @media (max-width: 700px) {
      flex-flow: column;
      > div {
        flex: 1;

        &:first-child:last-child {
          flex-flow: column;
        }

        > div {
          flex: 1 1 auto !important;
        }
      }
    }

    .catalog-card {
      display: flex;
      flex-flow: column;
      height: 100%;
      background-color: #fff;

      > div:first-child {
        flex: 1 1 auto;
        min-height: 130px;
        background-color: #eee;
        background-position: center;
        background-size: cover;
      }

      > div:last-child {
        padding: 10px 20px;
      }
    }
  }

  .catalog-items {
    display: flex;
    flex-flow: wrap;
    flex-direction: row;
    gap: 20px;

    .catalog-item {
      display: flex;
      flex-flow: column;
      flex: 0 0 auto;
      width: 100%;
      max-width: calc(25% - 20px);
      background-color: #fff;
      border: solid 1px #eee;
      &:hover {
        box-shadow: 0 2px 3px 0 #ddd;
      }

      &.catalog-item-next {
        display: flex;
        align-items: center;
        flex-flow: row;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        border: none !important;
        box-shadow: inset 0 0 0 1px #eee;

        > div {
          flex: 1 1 auto;
          text-align: center;
          border-top: none !important;
          opacity: 0.8;
          position: absolute;
          width: 100%;
        }

        &:hover {
          > div {
            opacity: 1;
          }
        }
      }

      > div:first-child {
        margin: -1px -1px;
        z-index: 1;

        > div {
          flex: 1;
          > button {
            border-color: #eee;
            width: 100%;
          }
        }
      }

      > div:last-child {
        border-top: solid 1px #eee;
        padding: 15px 20px;
      }

      .catalog-item-image {
        height: 150px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        cursor: pointer;
      }

      .catalog-item-desc {
        flex: 1 1 auto;
        padding: 10px 15px;
        border-top: solid 1px #eee;
        cursor: pointer;
        word-break: break-word;
      }

      h5 {
        color: #777;
      }

      @media (max-width: 1100px) {
        max-width: calc(25% - 20px);
      }
      @media (max-width: 900px) {
        max-width: calc(33.33% - 20px);
      }
      @media (max-width: 700px) {
        max-width: calc(50% - 20px);
      }
      @media (max-width: 500px) {
        max-width: 100%;
      }
    }

    &:not(.catalog-items-sm) {
      .catalog-item {
        max-width: calc(12.5% - 20px);

        @media (max-width: 2000px) {
          max-width: calc(14.28% - 20px);
        }
        @media (max-width: 1700px) {
          max-width: calc(16.66% - 20px);
        }
        @media (max-width: 1600px) {
          max-width: calc(20% - 20px);
        }
        @media (max-width: 1100px) {
          max-width: calc(25% - 20px);
        }
        @media (max-width: 900px) {
          max-width: calc(33.33% - 20px);
        }
        @media (max-width: 700px) {
          max-width: calc(50% - 20px);
        }
        @media (max-width: 500px) {
          max-width: 100%;
        }
      }
    }
  }
}

.product-detail {
  > div.product-detail-container {
    background-color: #fff;
    display: flex;
    flex-flow: row;
    gap: 20px;
    align-items: center;

    > div {
      flex: 0 0 auto;
    }

    > div:first-child {
      width: 300px;
      padding: 20px;
      > img {
        max-width: 95%;
      }
    }

    > div:last-child {
      flex: 1 1 auto;
      padding: 20px 30px;
    }

    @media (max-width: 1000px) {
      flex-flow: column;
    }
  }

  > div.product-other-detail-container {
    background-color: #fff;
    padding: 20px;

    nav {
      > a {
        color: $silver;
        font-size: 16px;

        border: none;
        &:hover {
          border: none;
        }

        &.active {
          border-bottom: solid 2px $olive;
          color: $black;
        }
      }
    }

    .tab-content {
      padding: 20px 0;
    }
  }
}

button.btn-icon-label {
  width: 100%;
  border-color: #eee;
  position: relative;
  overflow: hidden;

  > i {
    position: absolute;
    top: -100%;
    transform: translateY(0);
    left: 0;
    right: 0;
    z-index: 1;
    transition: all ease-out 0.2s;
    pointer-events: none;
  }
  > span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    z-index: 1;
    transition: all ease-out 0.2s;
    pointer-events: none;
  }
  > em {
    opacity: 0;
  }

  &:hover {
    > i {
      top: 50%;
      transform: translateY(-50%);
    }
    > span {
      top: 100%;
      transform: translateY(0);
    }
  }
}

.nav-breadcrumbs {
  font-size: 16px;
  font-weight: normal;
  .txt-grey {
    cursor: pointer;
    &:hover {
      color: $black !important;
    }
  }
}

.header-border {
  border-bottom: solid 2px #889362;
}

#floating-chatbox,
#chatbox-filters {
  position: fixed;
  z-index: 100;
  background-color: #fff;
}

#chatbox-overlay {
  position: fixed;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.1);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  transition: all ease 0.5s;
  &.open {
    opacity: 1;
    pointer-events: all;
  }
}

#chatbox-filters {
  right: 40px;
  bottom: 80px;
  overflow: auto;
  height: 0;
  transition: all ease 0.5s;
  transition-delay: 0.5s;
  padding: 0 10px;
  border-radius: 10px;
  box-shadow: 0 3px 5px 1px #999;
  width: 300px;
  opacity: 0;
  max-height: calc(100% - 180px);

  > span {
    display: inline-block;
    white-space: nowrap;
    background-color: #ddd;
    border-radius: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 0 5px;
    cursor: pointer;
    color: #555;
    &:hover {
      background-color: #ccc;
    }
  }
  &.open {
    bottom: 130px;
    padding: 10px;
    min-height: 70px;
    height: auto;
    opacity: 1;
  }
  &.hide {
    transition-delay: 0s !important;
    transition: all ease 0.3s !important;
    bottom: 80px;
    opacity: 0;
    width: 0;
  }

  > div {
    .result-header {
      color: $blue;
      &.disabled {
        color: #777;
        font-weight: bold;
      }
      margin: 5px 0;
    }

    .result-divider {
      margin: 5px 0;
      border-bottom: solid 1px #ddd;
    }
    .pointer {
      padding: 3px 6px;
    }
    .pointer:hover {
      background-color: #ddd;
    }
  }
}

#floating-chatbox {
  z-index: 101;
  right: -20px;
  bottom: 50px;
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  width: 70px;
  min-height: 70px;
  padding: 0 5px;
  box-shadow: 0 3px 5px 1px #999;
  transition: all ease 0.5s;
  overflow: hidden;
  &.hide {
    transition-delay: 0.5s;
  }
  .chatbox-icon {
    flex: 0 0 auto;
    font-size: 37px;
    background-color: $blue;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    cursor: pointer;

    i {
      transition: all ease 0.3s;
    }
    &:hover {
      background-color: darken($color: $blue, $amount: 20%);
      > i {
        transform: rotateZ(15deg);
      }
    }
  }

  &:hover:not(.open) {
    right: 10px;
  }

  &.open {
    border-radius: 40px;
    right: 40px;
  }

  .chatbox-input {
    flex: 1 1 auto;
    input {
      padding: 5px;
      border: none;
    }
    > div {
      width: 300px;
      height: 0;
      overflow: hidden;
    }
  }

  &.open {
    padding-right: 10px;
    width: 300px;
    .chatbox-input {
      overflow: hidden;
      input {
        padding: 5px;
      }
      > div {
        height: auto;
      }
    }
  }
}

div.show-sequence-tooltip {
  position: relative;
  &::after {
    content: "Hit Enter to Save";
    background: rgba(0, 0, 0, 0.75);
    color: #fff;
    position: absolute;
    z-index: 100;
    pointer-events: none;
    padding: 3px 6px;
    font-size: 14px;
    top: -32px;
    right: 0;
  }
  &.saving-sequence::after {
    content: "Saving...";
  }
}

input.input-inline-edit {
  background-color: transparent;
  border: none;
  &:focus,
  &:active,
  &:hover {
    background-color: #fff;
    box-shadow: 0 0 0 1px #333 !important;
  }
}
