@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/_mixins";
@import "react-datepicker/dist/react-datepicker.css";

//Colors
$green: #2ecd99;
$yellow: #f0c541;
$red: #ed6f56;
$blue: #4e9de6;
$pink: #f1a1c7;
$orange: #f3a464;
$gold: #c4996d;
$silver: #83878d;
$black: #212121;
$grey: #878787;
$light_grey: #efefef;
$menu_bg_col_1: #2a3e4c;
$menu_bg_col_2: #24333e;
$white: #fff;
$twitter_blue: #00aced;
$light_white: rgba(255, 255, 255, 0.1);
$xtra_lighter_grey: rgba(33, 33, 33, 0.05);
$xtra_lighter_white: rgba(255, 255, 255, 0.05);
$olive: #889362;
$system_green: #77a975;

//Transitions
$default_transtion: all ease 0.3s;

//Sizes
$sidenav-normal-width: 240px;
$sidenav-mobile-width: 44px;

html {
  scroll-behavior: smooth;
}

div.dropdown-menu {
  margin: 5px 0;
}

.btn-light:hover {
  background-color: #ddd;
}

.bg-green {
  background: $green !important;
}
.bg-grey {
  background: $grey !important;
}
.bg-light-grey {
  background: $light_grey !important;
}
.bg-yellow {
  background: $yellow !important;
}
.bg-light-yellow {
  background: lighten($color: $yellow, $amount: 30%);
}
.bg-red {
  background: $red !important;
}
.bg-light-red {
  background: lighten($color: $red, $amount: 30%);
}
.bg-blue {
  background: $blue !important;
}
.bg-pink {
  background: $pink !important;
}
.bg-light {
  background: $white !important;
}
.bg-dark {
  background: $black !important;
}
.bg-twitter {
  background: $twitter_blue !important;
}
.txt-dark {
  color: $black !important;
}
.txt-light {
  color: $white !important;
}
.txt-grey {
  color: $grey !important;
}
.txt-light-grey {
  color: $light_grey !important;
}
.txt-info,
.text-info {
  color: $pink !important;
}
.txt-warning,
.text-warning {
  color: $yellow !important;
}
.txt-primary,
.text-primary {
  color: $blue !important;
}
.txt-success,
.text-success {
  color: $green !important;
}
.txt-danger,
.text-danger {
  color: $red !important;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.2);
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

*:focus {
  outline: none !important;
}

select:focus,
input:focus,
textarea:focus,
button:focus {
  box-shadow: none !important;
}

.flex {
  display: flex;
}
.flex-row {
  flex-flow: row;
}
.flex-col {
  flex-flow: column;
}
.flex-wrap {
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1;
}
.flex-0 {
  flex: 0;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.flex-card-container {
  display: flex;
  flex: 1;
  flex-flow: column;
}

.flex > .card {
  flex: 1;
}

.flex-with-checkbox {
  display: flex;
  flex-flow: row;
  justify-items: center;
  align-items: center;
  > div {
    display: flex;
  }
  label {
    margin: 0;
  }
}
.flex-center {
  align-items: center;
  //gap: 5px;
}

.flex-end {
  align-items: flex-end;
  //gap: 5px;
}

.pagination .page-item.active > span {
  background-color: $olive;
  border-color: $olive;
  color: $white;
}

.pagination {
  input,
  input:focus,
  input:active {
    background-color: $white;
    border-color: #ddd;
    color: $blue;
    font-weight: bold;
    transition: all ease-in-out 0.2s;

    &.error {
      background-color: $red;
      border-color: $red;
      color: $white;
    }

    &::placeholder {
      color: #ccc;
      font-weight: bold;
    }
  }

  .paging-hit-enter {
    position: relative;
    &::before {
      content: " ";
      position: absolute;
      background-color: #222;
      height: 10px;
      width: 10px;
      top: -10px;
      left: 50%;
      transform: translateX(-50%) rotateZ(45deg);
      z-index: 1;
    }
    &::after {
      content: "Enter Page Number then Hit Enter";
      position: absolute;
      background-color: #222;
      color: #fff;
      white-space: nowrap;
      top: -22px;
      left: 50%;
      transform: translateX(-50%);
      padding: 2px 5px;
      border-radius: 3px;
      font-size: 10px;
      z-index: 1;
    }
  }
}

.chip-olive,
.chip-red,
.chip-orange,
.chip-green {
  background-color: $olive;
  border-radius: 5px;
  font-size: 11px;
  color: $white;
  padding: 3px 6px;
  border-radius: 50px;

  &.chip-orange {
    background-color: $orange;
  }

  &.chip-red {
    background-color: $red;
  }

  &.chip-green {
    background-color: $green;
  }
}

.mail-link {
  text-decoration: none;
  color: $blue;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.pointer {
  cursor: pointer;
}

.no-wrap {
  white-space: nowrap;
}

.form-control {
  appearance: auto;
}

.formModalOverlay {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.backdrop-init-position {
  transition: opacity ease-in-out 0.3s;
  opacity: 1;
  &.animate {
    opacity: 0.1;
  }
}

.dialog-init-position {
  transition: all ease 0.2s;
  opacity: 1;
  transform: translateY(0);

  &.animate {
    transform: translateY(-20px);
  }
}

.now-wrap {
  white-space: nowrap;
}

.display-none {
  display: none;
}

hr.light-grey-hr {
  border-top: 1px solid #dedede;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

table.table-sm {
  > thead {
    tr > th {
      color: #777;
      border-color: #ddd;
    }
  }
  > tbody {
    tr > td {
      color: #777;
    }
  }
}

table.table-label-desc {
  width: 100%;
  > tbody {
    > tr > td {
      padding: 0 !important;
      padding-right: 10px !important;
      vertical-align: text-top;
      &:first-child {
        white-space: nowrap;
      }
      &:last-child {
        padding-right: 0 !important;
      }
    }
  }
}

table:not(.table-sm):not(.no-format):not(.table-label-desc) {
  margin-bottom: 0;

  > thead > tr > th {
    font-weight: normal;
    font-size: 12px;
  }
  > tbody > tr > td {
    color: #878787;
    font-size: 16px;
  }
  > tbody > tr > td,
  > thead > tr > th {
    border-color: #dedede !important;
    border-bottom-color: #dedede !important;
    padding: 8px !important;

    &:first-child {
      padding-left: 20px !important;
    }
    &:last-child {
      padding-right: 20px !important;
    }
  }
  > thead > tr > th {
    padding-top: 16px !important;
    padding-bottom: 10px !important;
  }
}

table {
  tbody > tr > td {
    vertical-align: top;

    small {
      color: #aaa;
    }
  }
}

table tbody tr td a {
  text-decoration: none;
  color: $blue;
}

button,
textarea,
input,
select {
  border-radius: 0 !important;
}

.DayPickerInput {
  display: block !important;
  z-index: 10;
  input {
    display: block;
    border: 1px solid #ced4da !important;
    padding: 0.3rem 0.75rem;
    width: 100%;
  }
}

.sysDateTimePicker {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  gap: 10px;

  > div:first-child {
    flex: 1;
    min-width: 110px;
  }

  > div:last-child {
    display: flex;
    flex-flow: row;
    gap: 10px;
  }

  select {
    width: 70px;
  }
}

.dataRangpicker {
  .sysDateTimePicker {
    input {
      padding: 3px 8px;

      &::placeholder {
        font-size: 14px;
      }
    }
  }
}

.show-on-print {
  display: none;
}

.no-decoration {
  text-decoration: none;
}

.switch-container {
  display: inline-flex;
  gap: 15px;
}

button.switch-button {
  transition: all ease 0.3s;
  border: none;
  border-radius: 30px !important;
  background-color: #bbb;
  min-height: 25px;
  width: 55px;
  position: relative;

  &[disabled] {
    opacity: 0.7;
  }

  &::after {
    font-family: "FontAwesome";
    font-weight: 400;
    content: "\f00d";
    color: #fff;
    display: block;
    text-align: right;
    padding: 0 4px;
  }

  &::before {
    transition: all ease 0.3s;
    content: " ";
    position: absolute;
    height: 21px;
    width: 21px;
    border-radius: 50%;
    background-color: #fff;
    top: 2px;
    left: 2px;
  }

  &.switch-on {
    background-color: $blue;
    &::before {
      left: 32px;
    }

    &::after {
      content: "\f00c";
      text-align: left;
    }
  }
}

.overflow-auto {
  overflow: auto;
}

.min-height-100 {
  min-height: 100px;
}

.break-all {
  word-break: break-all;
}

.break-word {
  word-break: break-word;
}

.border-none {
  border: none !important;
}

.search-box {
  width: 350px;
  max-width: 100%;
}

.remirror-button {
  color: rgba(0, 0, 0, 0);
  position: relative;

  &:hover {
    color: rgba(0, 0, 0, 0) !important;
    &::after {
      color: #fff;
    }
  }

  &::after {
    color: blue;
    font-family: "FontAwesome";
    font-weight: 400;
    content: "\f0d7";
    display: block;
    text-align: center;
    position: absolute;
    top: 2px;
    left: 0;
    right: 0;
  }
}

.remirror-theme {
  display: flex;
  flex-flow: column;
  gap: 10px;
  p {
    margin: 0;
  }
  .remirror-editor-wrapper {
    order: 2;
    //overflow: auto;

    table {
      display: block;
      //width: 100%;
    }

    > div[contenteditable="false"] {
      padding: 0;
      box-shadow: none;
      overflow: hidden;

      table {
        border: none;
        // th {
        //   display: none;
        // }

        th,
        td {
          border: none;
        }
      }
    }

    blockquote {
      padding-left: 1.5em;
      border-left: solid 2px #ddd;
    }

    code {
      border-radius: 4px;
      border: 1px solid #e8ebed;
      background: #f4f7fa;
      padding: 3px 4px;
      font-size: 80%;
    }

    ul[data-task-list] {
      // list-style: none;
      // padding: 0;
      // padding-left: 12px;
      > li {
        display: flex;
        flex-flow: row;
        align-items: flex-start;
        gap: 8px;

        &[data-checked] {
          text-decoration: line-through;
        }
      }
    }

    [data-text-highlight-mark] {
      color: inherit;
    }
  }
  .doc-editor-toolbar {
    order: 1;

    > .btn-group {
      margin-bottom: 5px;
    }

    .btn {
      > i.fa-font.text-highlight {
        border: solid 1px #222;
        padding: 2px 5px;
      }
      &.btn-secondary {
        > i.fa-font.text-highlight {
          border: solid 1px #fff;
        }
      }
    }

    .default-colors-container {
      padding: 0 15px;
      padding-bottom: 10px;
      display: flex;
      flex-flow: wrap;
      gap: 6px;

      > button {
        flex: 0 0 auto;
        display: inline-block;
        height: 20px;
        width: 20px;
        box-shadow: 0 0 0 1px #ccc;

        &.active-color {
          box-shadow: 0 0 0 2px red;
        }

        &.remove-color {
          position: relative;
          > i {
            color: red;
            position: absolute;
            top: 2px;
            left: 3px;
          }
        }
      }
    }
  }
}