.toastContainer {
  position: fixed;
  z-index: 999999999999;
  top: 10px;
  right: 25px;
  opacity: 1;
  display: flex;
  flex-flow: column;

  &:hover {
    opacity: 1;
  }

  > div {
    margin-bottom: 10px;
    //background-color: lightblue;
    border-radius: 3px;
    box-shadow: 0 1px 5px 0 #bbb;
    color: #222;
    overflow: hidden;
    animation-name: toastAnim;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    display: flex;
    max-width: 300px;
    transition: all linear 0.3s;
    justify-items: center;
    align-items: center;
    flex: 1;
    flex-shrink: 1;
    font-size: 15px;
    align-items: flex-start;

    &.clearAll {
      background-color: #fff;
      font-size: 12px;
      cursor: pointer;
      padding: 5px;
      > strong {
        text-align: center;
        flex: 1;
      }
      &:hover {
        background-color: #eee;
      }
    }

    &.remove-toast {
      opacity: 0;
      pointer-events: none;
      z-index: -1;
      flex: 0 0 0;
      flex-basis: 0;
      height: 0;
      margin-bottom: 0;
      transform: translateX(100%);
      > * {
        opacity: 0;
      }
    }

    > small {
      flex: 1;
      padding-right: 10px;
      padding: 8px 12px;
      word-break: break-word;
      max-height: 70vh;
    }

    > i {
      cursor: pointer;
      opacity: 0.5;
      padding-right: 12px;
      margin-top: 10px;
      &:hover {
        opacity: 1;
      }
    }

    &.toast-info {
      border: 2px solid #50a5f1 !important;
      background-color: rgba(80, 165, 241, 0.9) !important;
    }

    &.toast-success {
      border: 2px solid #34c38f !important;
      background-color: rgba(52, 195, 143, 0.9) !important;
    }

    &.toast-warning {
      border: 2px solid #f1b44c !important;
      background-color: rgba(241, 180, 76, 0.9) !important;
    }

    &.toast-error {
      background-color: rgba(244, 106, 106, 0.9);
      border: 2px solid #f46a6a;
    }
  }
}

@keyframes toastAnim {
  0% {
    opacity: 0;
    margin-top: -15px;
  }

  100% {
    opacity: 1;
    margin-top: 0;
  }
}
